import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import { FORM_DEFAULT_AVERAGE_LEAD } from "pages/Report/constants/constants";
import {
  useReportCommonTransactionCategoryListQuery,
  useReportCommonGetReportBasisQuery,
  useReportCommonGetLocationTypeQuery,
  useReportCommonGetLocationGroupQuery,
  useReportCommonGetOperationQuery,
  useReportCommonGetRoutePointQuery,
  useReportCommonGetRPackageStatusFromQuery,
  useReportCommonGetRPackageStatusToQuery,
  useReportCommonGetPackStatusFromQuery,
  useReportCommonGetPackStatusToQuery,
  useReportCommonGetRPackageOwnerQueryDN96I0,
  useReportCommonGetRPackageTypeQueryDN96I0,
  useExcelDownloadOnlineJavaMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { findObject, responseErrors } from "utils";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { waitAndDownloadExcel } from "shared/services/common-dowload/download-helper-service";
import { COMMON_ENUM } from "shared/constants";
import { useSelector } from "react-redux";
export default function AverageLeadTimeReport(props) {
  const userInfo = useSelector(state => state.auth.user);
  const { setMsgError, setMsgAlert } = props;
  const [form, setForm] = useState(FORM_DEFAULT_AVERAGE_LEAD);
  //API
  const { data: reportBasisList } = useReportCommonGetReportBasisQuery();
  const { data: rPkgOwnerList } = useReportCommonGetRPackageOwnerQueryDN96I0();
  const { data: rPkgTypeList } = useReportCommonGetRPackageTypeQueryDN96I0(form.rPkgTypeOwner);

  const { data: transactionCategoryList } = useReportCommonTransactionCategoryListQuery();
  const { data: locationTypeList } = useReportCommonGetLocationTypeQuery();
  const { data: locationGroupList } = useReportCommonGetLocationGroupQuery(form?.locationType);
  const { data: operationList } = useReportCommonGetOperationQuery(form?.locationGroup);
  const { data: routePointList } = useReportCommonGetRoutePointQuery({
    locationGroup: form?.locationGroup,
    operationDesc: form?.operation,
  });
  const { data: rPkgStatusFromList } = useReportCommonGetRPackageStatusFromQuery({
    locationGroup: form?.locationGroup,
    operationDesc: form?.operation,
  });
  const { data: rPkgStatusToList } = useReportCommonGetRPackageStatusToQuery({
    locationGroup: form?.locationGroup,
    operationDesc: form?.operation,
  });
  const { data: packStatusFromList } = useReportCommonGetPackStatusFromQuery({
    locationGroup: form?.locationGroup,
    operationDesc: form?.operation,
  });
  const { data: packStatusToList } = useReportCommonGetPackStatusToQuery({
    locationGroup: form?.locationGroup,
    operationDesc: form?.operation,
  });
  useEffect(() => {
    document.getElementById("select-report-basis").focus();
  }, []);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const generateReport = useExcelDownloadOnlineJavaMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const convertLocationGroup = () => {
    if (!form?.locationGroupIdx) return "";
    if (form?.locationType === "R") {
      return form?.locationGroupIdx;
    } else if (form?.locationType === "P") {
      return findObject({
        data: locationGroupList,
        value: form?.locationGroupIdx,
        property: "locationGroupId",
        field: "locationGroup",
      });
    }
    return "";
  };
  const handleDownload = async () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      const body = {
        displayName: userInfo.displayName,
        dataOwner: userProfile.dataOwner,
        userId: userInfo.userName,
        reportBasis: form?.reportBasis,
        locationType: form?.locationType,
        locationGroup: convertLocationGroup(),
        locationGroupId: form?.locationGroupIdx,
        operation: form?.operation, // "FG TO DLV",
        routePointId: form?.routePointId,
        routePointName: form?.routePointId
          ? findObject({
              data: routePointList,
              value: form?.routePointId,
              property: "routePointId",
              field: "routePointName",
            })
          : "",
        rPackageStatusFrom: form?.rpkgStatusFrom,
        rPackageStatusTo: form?.rpkgStatusTo,
        packStatusFrom: form?.packStatusFrom,
        packStatusTo: form?.packStatusTo,
        periodFrom: form?.periodFrom, // DD/MM/YYYY
        periodTo: form?.periodTo, // optional DD/MM/YYYY
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        transactionCategory: form?.transactionCategory,
      };
      console.log(form, body);

      const pathReport = "generate-average-lead-time-report";
      startLoading();
      const response = await generateReport(body, pathReport);
      console.log(response);

      waitAndDownloadExcel(60, response.data, setMsgError, stopLoading);
      // stopLoading();
      setDisabledSubmitBtn(false);

      return;
    } catch (error) {
      const msgError = responseErrors(error);
      setMsgError(msgError);
      stopLoading();
      setDisabledSubmitBtn(false);
      return;
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={5} style={{ display: "flex" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Report : </strong>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "LDN960I0", value: "Average Lead Time Report" }]}
            value={"LDN960I0"}
            disabled
          />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Report Basis : </strong>
          <DropDown
            id="select-report-basis"
            fullWidth
            required
            value={form?.reportBasis}
            defaultValue=""
            placeholder="<Select>"
            menu={reportBasisList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                reportBasis: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Location Type : </strong>
          <DropDown
            id="select-location-type"
            fullWidth
            required
            value={form?.locationType}
            defaultValue=""
            placeholder="<Select>"
            menu={locationTypeList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationType: e.target.value,
                locationGroupIdx: "",
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>*Location Group : </strong>
          <DropDown
            id="select-location-group"
            fullWidth
            required
            value={form?.locationGroupIdx}
            defaultValue=""
            placeholder="<Select>"
            menu={locationGroupList?.map((val, index) => ({
              key: form?.locationType === "R" ? val?.locationGroup : val.locationGroupId,
              value: val?.locationGroup,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationGroupIdx: e.target.value,
                operation: "",
              }))
            }
            disabled={!form?.locationType.length}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Operation : </strong>
          <DropDown
            id="select-operation"
            fullWidth
            value={form?.operation}
            defaultValue=""
            placeholder="<All>"
            menu={operationList?.map((val, index) => ({
              key: val?.operationDesc,
              value: val?.operationDesc,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                operation: e.target.value,
                routePointId: "",
                rpkgStatusFrom: "",
                rpkgStatusTo: "",
                packStatusFrom: "",
                packStatusTo: "",
              }))
            }
            disabled={!form.locationGroupIdx || form?.locationType === COMMON_ENUM.LOCATION_TYPE_PATH}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Route Point Name : </strong>
          <DropDown
            id="select-route-point-name"
            fullWidth
            value={form?.routePointId}
            defaultValue=""
            placeholder="<All>"
            menu={routePointList?.map((val, index) => ({
              key: val?.routePointId,
              value: val?.routePointName,
            }))}
            disabled={!form?.operation.length || form?.locationType === COMMON_ENUM.LOCATION_TYPE_PATH}
            onChange={e =>
              setForm(old => ({
                ...old,
                routePointId: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Status From : </strong>
          <DropDown
            id="select-rpkg-status-from"
            fullWidth
            value={form?.rpkgStatusFrom}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgStatusFromList?.map((val, index) => ({
              key: val?.rPkgStsFrom,
              value: val?.rPkgStsFrom,
            }))}
            disabled={!form?.operation.length || form?.locationType === COMMON_ENUM.LOCATION_TYPE_PATH}
            onChange={e =>
              setForm(old => ({
                ...old,
                rpkgStatusFrom: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Status To : </strong>
          <DropDown
            id="select-rpkg-status-to"
            fullWidth
            value={form?.rpkgStatusTo}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgStatusToList?.map((val, index) => ({
              key: val?.rPkgStsTo,
              value: val?.rPkgStsTo,
            }))}
            disabled={!form?.operation.length || form?.locationType === COMMON_ENUM.LOCATION_TYPE_PATH}
            onChange={e =>
              setForm(old => ({
                ...old,
                rpkgStatusTo: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Pack Status From : </strong>
          <DropDown
            id="select-pack-status-from"
            fullWidth
            value={form?.packStatusFrom}
            defaultValue=""
            placeholder="<All>"
            menu={packStatusFromList?.map((val, index) => ({
              key: val?.packStsFrom,
              value: val?.packStsFrom,
            }))}
            disabled={!form?.operation.length || form?.locationType === COMMON_ENUM.LOCATION_TYPE_PATH}
            onChange={e =>
              setForm(old => ({
                ...old,
                packStatusFrom: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Pack Status To : </strong>
          <DropDown
            id="select-pack-status-to"
            fullWidth
            value={form?.packStatusTo}
            defaultValue=""
            placeholder="<All>"
            menu={packStatusToList?.map((val, index) => ({
              key: val?.packStsTo,
              value: val?.packStsTo,
            }))}
            disabled={!form?.operation.length || form?.locationType === COMMON_ENUM.LOCATION_TYPE_PATH}
            onChange={e =>
              setForm(old => ({
                ...old,
                packStatusTo: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4} style={{ display: "flex", paddingTop: "0px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>*Period From : </strong>
          <DatePickerField
            id="input-period-from"
            className="input-period-from"
            onChange={e =>
              setForm(old => ({
                ...old,
                periodFrom: e,
              }))
            }
            value={form?.periodFrom}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={2} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px" }}>(DD/MM/YYYY)</strong>
        </Grid>
        <Grid item xs={4} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "338px" }}>*Period To : </strong>
          <DatePickerField
            id="input-period-to"
            className="input-period-to"
            onChange={e =>
              setForm(old => ({
                ...old,
                periodTo: e,
              }))
            }
            value={form?.periodTo}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} style={{ display: "flex", paddingTop: "3px" }}>
          <strong style={{ margin: "auto 0px" }}>(DD/MM/YYYY)</strong>
        </Grid>
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Owner : </strong>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgOwnerList?.map(val => ({
              key: val?.rPkgOwnerCompAbbr,
              value: val?.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>R-Package Type : </strong>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgTypeList?.map(val => ({
              key: val?.rPkgType,
              value: val?.rPkgType,
            }))}
            onChange={e => {
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }));
            }}
            disabled={!form?.rPkgTypeOwner.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5} style={{ display: "flex", paddingTop: "6px" }}>
          <strong style={{ margin: "auto 0px", width: "300px" }}>Transaction Category : </strong>
          <DropDown
            id="select-transaction-category"
            fullWidth
            value={form?.transactionCategory}
            defaultValue=""
            placeholder="<All>"
            menu={transactionCategoryList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionCategory: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <ButtonAction
          handleDownload={handleDownload}
          setFrom={setForm}
          defaultFrom={FORM_DEFAULT_AVERAGE_LEAD}
          disabled={disabledSubmitBtn}
          setDisabled={setDisabledSubmitBtn}
          clearMsg={() => {
            setMsgAlert([]);
            setMsgError([]);
            document.getElementById("select-report-basis").focus();
          }}
          firstField={"select-report-basis"}
        />
      </Grid>
    </>
  );
}
